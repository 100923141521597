import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './master.css';
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfigWeb } from 'vcl-common';
import './assets/style/color.css';
import './assets/style/theme.css';

const pca = new PublicClientApplication(msalConfigWeb);
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult; // Type assertion here
    if (payload.account) {
      pca.setActiveAccount(payload.account);
    }
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error(JSON.stringify(event));
  }
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>,
  );
}

reportWebVitals(console.info);

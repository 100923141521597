import '@flowplayer/player/flowplayer.css';
import { useEffect, useRef, useState } from 'react';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import flowplayer from '@flowplayer/player';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import KeyboardPlugin from '@flowplayer/player/plugins/keyboard';
import ThumbnailsPlugin from '@flowplayer/player/plugins/thumbnails';
import QualityPlugin from '@flowplayer/player/plugins/qsel';
import SpeedPlugin from '@flowplayer/player/plugins/speed';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import AirplayPlugin from '@flowplayer/player/plugins/airplay';
import FloatOnScrollPlugin from '@flowplayer/player/plugins/float-on-scroll';
import Shareplugin from '@flowplayer/player/plugins/share';

import { ENDED, PAUSE, PLAYING } from '@flowplayer/player/core/events';

// Register plugins
flowplayer(HLSPlugin);
flowplayer(KeyboardPlugin);
flowplayer(ThumbnailsPlugin);
flowplayer(QualityPlugin);
flowplayer(SpeedPlugin);
flowplayer(ChromecastPlugin);
flowplayer(AirplayPlugin);
flowplayer(FloatOnScrollPlugin);
flowplayer(Shareplugin);

const handleVideoStateChange = (event: Event) => {
  switch (event.type) {
    case PAUSE:
      console.log('Video paused');
      break;
    case PLAYING:
      console.log('Video playing');
      break;
    case ENDED:
      console.log('Video ended');
      break;
    default:
      break;
  }
};

const PLAYER_ID = process.env.REACT_APP_VIDEO_PLAYER_ID || '';
const DEMO_TOKEN = process.env.REACT_APP_VIDEO_PLAYER_TOKEN || '';

interface IVideoPlayerProps {
  videoUrl: string;
  thumbnailUrl: string;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const playerApi = useFlowplayer(playerRef);
  const [playerConfig, setPlayerConfig] = useState<Record<
    string,
    unknown
  > | null>(null);

  useEffect(() => {
    const fetchPlayerConfig = async () => {
      try {
        const response = await fetch(
          `https://ljsp.lwcdn.com/web/public/native/config/${PLAYER_ID}`,
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch player config: ${response.statusText}`,
          );
        }
        const config = await response.text();
        setPlayerConfig(JSON.parse(config));
      } catch (error) {
        console.error('Error fetching player config:', error);
      }
    };

    fetchPlayerConfig();
  }, []);

  useEffect(() => {
    if (!playerApi) return;
    playerApi.on([PAUSE, PLAYING, ENDED], handleVideoStateChange);
    return () => {
      playerApi.off(PAUSE, handleVideoStateChange);
      playerApi.off(PLAYING, handleVideoStateChange);
      playerApi.off(ENDED, handleVideoStateChange);
    };
  }, [playerApi]);

  return (
    <Flowplayer
      src={videoUrl}
      token={DEMO_TOKEN}
      ref={playerRef}
      opts={{
        autoplay: false,
        poster: thumbnailUrl,
        ...playerConfig,
        ratio: '16:9',
      }}
    />
  );
};

export default VideoPlayer;

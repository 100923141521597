import { getHostDependentImageUrl, IWebcast, IPageMetadata } from 'vcl-common';
import Hero from '../../sharedComponents/hero/hero';
import FeaturedWebcast from '../../sharedComponents/FeaturedWebcast/FeaturedWebcast';
import Carousel from '../../sharedComponents/thumbnail/carousel';
import './channel.css';
import VideoCard from '../../sharedComponents/VideoCard/VideoCard';
import { useFeed } from '../../hooks/useFeed';
import { useNavigate } from 'react-router-dom';

interface IChannelProps {
  pageMetadata: IPageMetadata;
}

function Channel(props: IChannelProps) {
  const { globalSettings, urlInfo } = props.pageMetadata;
  const navigate = useNavigate();

  const feedParams = {
    organizationId: Number(globalSettings?.organization?.id),
    channelId: Number(urlInfo?.channel?.id),
  };

  const { feedData } = useFeed({
    feedType: 'channelFeed',
    status: 'future',
    ...feedParams,
  });

  const fetureWebcast = feedData.data[0] as IWebcast;

  return (
    <>
      <Hero
        heroImage={getHostDependentImageUrl(urlInfo?.channel.heroUrl)}
        imgtext={urlInfo?.channel.title || ''}
        descText={urlInfo?.channel}
        preHeader={''}
      />
      {fetureWebcast && (
        <FeaturedWebcast
          heroUrl={getHostDependentImageUrl(fetureWebcast?.heroUrl)}
          relativeUrl={fetureWebcast?.relativeUrl}
          startTime={fetureWebcast?.startTime}
          title={fetureWebcast?.title}
          titleTwo={fetureWebcast?.title2}
          description={fetureWebcast?.description}
        />
      )}
      <div className="channel-carousel-container">
        <Carousel
          headerTitle="Live webcasts"
          feedType="channelFeed"
          status="current"
          {...feedParams}
          renderItem={(item: IWebcast, index: number) => (
            <VideoCard key={index} isLive item={item} />
          )}
        />
      </div>
      <div className="channel-carousel-container">
        <Carousel
          headerTitle="Upcoming webcasts"
          feedType="channelFeed"
          status="future"
          {...feedParams}
          renderItem={(item: IWebcast, index: number) => (
            <VideoCard key={index} item={item} />
          )}
        />
        <Carousel
          headerTitle="Latest webcasts"
          feedType="channelFeed"
          status="past"
          {...feedParams}
          renderItem={(item: IWebcast, index: number) => (
            <VideoCard key={index} item={item} />
          )}
        />
      </div>
      <div className="video-btn-container">
        <button
          onClick={() => navigate(`/videos?channelId=${feedParams.channelId}`)}
          className="primaryBtn video-btn"
        >
          Show All Videos
        </button>
      </div>
    </>
  );
}

export default Channel;

import Markdown from 'react-markdown';
import './CountdownDescription.css';

type Webcast = {
  lobbyDescription?: string;
  lobbyDescriptionAsHTML?: string;
  lobbyDescriptionFieldContainsHTML?: boolean;
  description?: string;
  descriptionAsHTML?: string;
  descriptionFieldContainsHTML?: boolean;
};

interface CountdownDescriptionProps {
  webcast: Webcast;
}

const CountdownDescription: React.FC<CountdownDescriptionProps> = ({
  webcast,
}) => {
  if (webcast.lobbyDescription) {
    if (webcast.lobbyDescriptionFieldContainsHTML) {
      return (
        <div
          className="discriptionHTML"
          dangerouslySetInnerHTML={{ __html: webcast.lobbyDescriptionAsHTML! }}
        />
      );
    }
    return <div className="vl-description">{webcast.lobbyDescription}</div>;
  }

  if (webcast.descriptionFieldContainsHTML) {
    return (
      <div
        className="vl-description"
        dangerouslySetInnerHTML={{ __html: webcast.descriptionAsHTML! }}
      />
    );
  }

  if (webcast.description) {
    return (
      <div className="vl-description">
        <Markdown>{webcast.description}</Markdown>
      </div>
    );
  }
  return null;
};

export default CountdownDescription;

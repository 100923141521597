import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import './SearchBox.css';

export interface InputContainerProps {
  labelText?: string;
  errorMessage?: string;
  disabled?: boolean;
  onSearch?: (query: string) => void;
  placeholder?: string;
}

const DEBOUNCE_DELAY = 500;

const SearchBox = ({
  labelText,
  errorMessage,
  disabled,
  onSearch,
  placeholder,
  ...inputFieldProps
}: InputContainerProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="search-box-container">
      <div className="input-container">
        {labelText && <label>{labelText}</label>}
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            disabled={disabled}
            placeholder={placeholder}
            value={searchQuery}
            onChange={handleSearch}
            className="input-field"
            {...inputFieldProps}
          />
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <div style={{ display: 'flex' }}>
        <button className="search-button">
          <CiSearch className="search-icon" />
        </button>
      </div>
    </div>
  );
};

export default SearchBox;

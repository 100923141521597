import CallToAction from '../../../sharedComponents/Cta/CallToAction';
import NavTAb from '../../../sharedComponents/navTab/navTab';
import VideoPlayer from '../../player/videoPlayer';
import { IPageMetadata, IWebcast, getHostDependentImageUrl } from 'vcl-common';
import VideoDetail from './VideoDetail';

interface VideoContainerProps {
  webcast: IWebcast;
  pageMetadata: IPageMetadata;
  isShowEndInfo: () => boolean | undefined;
  canShowCTA: () => boolean | undefined;
  videoIsVisible: () => boolean;
  isAdmin: boolean;
}

const VideoContainer: React.FC<VideoContainerProps> = ({
  webcast,
  pageMetadata,
  isShowEndInfo,
  canShowCTA,
  videoIsVisible,
  isAdmin,
}) => {
  const videoUrl =
    webcast?.wvStreamIsLive && webcast?.hlsPublicLiveUrl
      ? webcast.hlsPublicLiveUrl
      : webcast?.hlsPublicVodUrl || '';

  const thumbnailUrl = getHostDependentImageUrl(webcast?.thumbnailUrl);

  return (
    <div>
      <div className="videoContainer">
        {(pageMetadata?.urlInfo?.webcast &&
          pageMetadata?.urlInfo?.webcast?.hlsPublicLiveUrl) ||
        pageMetadata?.urlInfo?.webcast.hlsPublicVodUrl ? (
          <VideoPlayer videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />
        ) : (
          <img
            alt="video player"
            src={getHostDependentImageUrl(
              pageMetadata?.urlInfo?.webcast?.thumbnailUrl,
            )}
          />
        )}
      </div>
      {!isShowEndInfo() && (
        <>
          <div className="navContainer">
            <NavTAb pageMetadata={pageMetadata} />
          </div>
          <div className="titlesContainer">
            {canShowCTA() && <CallToAction webcast={webcast} />}
            {videoIsVisible() && (
              <VideoDetail isAdmin={isAdmin} webcast={webcast} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VideoContainer;

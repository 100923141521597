import { useState, useMemo } from 'react';
import {
  useLocalStorage,
  AppType,
  HTTP_METHODS,
  useVclApi,
  VclApiGetType,
  Constants,
  IWebcast,
} from 'vcl-common';
import { IWebcastFilter } from './useQueryParams';

const FEED_URLS = {
  webcastFeed: 'webcastFeed',
  channelFeed: 'channelFeed',
  recommendedFeed: 'recommendedFeed',
} as const;

const FEED_STATUS_FILTER = {
  past: 'includePast',
  current: 'includeCurrent',
  future: 'includeFuture',
} as const;

export type FeedType = keyof typeof FEED_URLS;
export type FeedStatus = keyof typeof FEED_STATUS_FILTER;

interface IFeedProps {
  feedType: FeedType;
  status: FeedStatus;
  organizationId: number;
  channelId?: number;
  webcastId?: number;
}

interface IFeedData {
  data: IWebcast[] | [];
  currentPage: number;
  totalPages: number;
}

const getFilterByStatus = (status: FeedStatus): Partial<IWebcastFilter> => {
  const baseFilter = {
    orderBy: 'startTime',
  };

  switch (status) {
    case 'future':
      return {
        ...baseFilter,
        includeFuture: true,
        orderByDescending: false,
      };
    case 'past':
      return {
        ...baseFilter,
        includePast: true,
        orderByDescending: true,
      };
    case 'current':
      return {
        ...baseFilter,
        includeCurrent: true,
        orderByDescending: false,
      };
    default:
      return baseFilter;
  }
};

export const useFeed = ({
  feedType,
  status,
  organizationId,
  channelId,
  webcastId,
}: IFeedProps) => {
  const { getOrgSetting, getApiKey } = useLocalStorage();
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = useMemo(() => {
    const setting = getOrgSetting('RollupNoOfThumbnails');
    return setting ? Number(setting) : 4;
  }, [getOrgSetting]);

  const filter: IWebcastFilter = useMemo(() => {
    const filters = getFilterByStatus(status);

    if (feedType === 'channelFeed' && channelId) {
      filters.channelId = channelId;
    }

    if (
      (feedType === 'webcastFeed' || feedType === 'recommendedFeed') &&
      webcastId
    ) {
      filters.webcastId = webcastId;
    }

    return filters as IWebcastFilter;
  }, [feedType, status, channelId, webcastId]);

  const apiGetProps = useMemo(() => {
    return {
      apiUrl: Constants.routes.api.allVideos,
      method: HTTP_METHODS.GET,
      appType: AppType.Web,
      getType: VclApiGetType.Batch,
      fetchImmediately: true,
      params: {
        filter: JSON.stringify(filter),
        page: currentPage,
        pageSize: pageSize,
        apiKey: getApiKey(),
        organizationId,
      },
    };
  }, [filter, currentPage, pageSize, getApiKey, organizationId]);

  const { apiRequestInProgress, apiResponseBatch, apiError } =
    useVclApi(apiGetProps);

  const totalPages = useMemo(() => {
    const totalItems = apiResponseBatch?.properties.totalItemCount ?? 0;
    return Math.ceil(totalItems / pageSize);
  }, [apiResponseBatch?.properties.totalItemCount, pageSize]);

  return {
    feedData: {
      data: apiResponseBatch.items,
      currentPage,
      totalPages,
    } as IFeedData,
    loading: apiRequestInProgress,
    error: apiError,
    setCurrentPage,
  };
};

import Banner from '../../../sharedComponents/Banner/Banner';
import Countdown from '../../../sharedComponents/countdown/countdown';
import { IPageMetadata, getHostDependentImageUrl } from 'vcl-common';
import { formatDateAndTime } from '../webcast';
import NavTAb from '../../../sharedComponents/navTab/navTab';
import CountdownDescription from './CountdownDescription';
import CallToAction from '../../../sharedComponents/Cta/CallToAction';

interface CountDownContainerProps {
  webcast: Record<string, any>;
  pageMetadata: IPageMetadata;
  setIsFutureWebcast: (isFutureWebcast: boolean) => void;
  isShowEndInfo: () => boolean | undefined;
  canShowCTA: () => boolean | undefined;
}

const CountDownContainer: React.FC<CountDownContainerProps> = ({
  pageMetadata,
  setIsFutureWebcast,
  webcast,
  isShowEndInfo,
  canShowCTA,
}) => {
  return (
    <div>
      <Banner
        date={webcast?.startTime && formatDateAndTime(webcast?.startTime)}
        title={webcast.titleCombined ?? ''}
        image={getHostDependentImageUrl(
          pageMetadata?.urlInfo?.webcast?.thumbnailUrl,
        )}
      >
        <Countdown
          startDate={new Date(webcast?.startTime as any)}
          customDate={
            webcast?.useCustomCDSwitchOver === true
              ? new Date(webcast?.customCDSwitchOverTime as any)
              : null
          }
          switchToStream={() => {
            setIsFutureWebcast(false);
          }}
        />
      </Banner>
      {!isShowEndInfo() && (
        <>
          <NavTAb pageMetadata={pageMetadata} />
          <div className="titlesContainer">
            {canShowCTA() && <CallToAction webcast={webcast} />}
            <CountdownDescription webcast={webcast} />
          </div>
        </>
      )}
    </div>
  );
};

export default CountDownContainer;

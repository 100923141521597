import { ReactNode } from 'react';
import Pagination from '../pagination/pagination';
import './carousel.css';
import VideoCardSkeleton from '../VideoCard/VideoCardSkeleton';
import { IWebcast } from 'vcl-common';
import { useFeed, FeedType, FeedStatus } from '../../hooks/useFeed';

interface ICarouselProps {
  headerTitle: string;
  feedType: FeedType;
  status: FeedStatus;
  organizationId: number;
  channelId?: number;
  webcastId?: number;
  renderItem: (item: IWebcast, index: number) => ReactNode;
}

const Carousel = ({
  headerTitle,
  feedType,
  status,
  organizationId,
  channelId,
  webcastId,
  renderItem,
}: ICarouselProps) => {
  const { feedData, loading, error, setCurrentPage } = useFeed({
    feedType,
    status,
    organizationId,
    channelId,
    webcastId,
  });

  const { data, currentPage, totalPages } = feedData;

  return (
    <div>
      {error.isError && <p>{error.errorMessage}</p>}
      {!error.isError && !loading && data.length > 0 && (
        <>
          <h4 className="carousalMainHeading">{headerTitle}</h4>
          <div className="carousalVideoCard">
            {data.map((item: IWebcast, index: number) =>
              renderItem(item, index),
            )}
          </div>
          {data.length > 4 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
        </>
      )}
      {loading && (
        <>
          <div className="carousalVideoCard">
            {Array.from({ length: 4 }).map((_, index) => (
              <VideoCardSkeleton key={index} />
            ))}
          </div>
          <div className="skelatonPagination" />
        </>
      )}
    </div>
  );
};

export default Carousel;

import React from 'react';
import './Datepicker.css';

interface DatePickerProps {
  selectedDate?: string;
  onChange?: (date: string) => void;
  minDate?: string;
  maxDate?: string;
  className?: string;
  disabled?: boolean;
  id: string;
  name: string;
  required?: boolean;
  label?: string;
  defaultValue?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  onChange,
  minDate,
  maxDate,
  className = '',
  disabled = false,
  id,
  name,
  required = false,
  label,
  defaultValue,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange instanceof Function) onChange(event.target.value);
  };

  return (
    <div className={`date-picker-container ${className}`}>
      <label className="date-label" htmlFor={id}>
        {label}
      </label>
      <input
        type="date"
        id={id}
        name={name}
        className="date-input"
        value={selectedDate}
        onChange={handleChange}
        min={minDate}
        max={maxDate}
        disabled={disabled}
        required={required}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default DatePicker;

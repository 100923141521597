import React from 'react';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeOnOverlay?: boolean;
  children: React.ReactNode;
  isBottomSheetOnMobile?: boolean;
}

export default function Modal({
  isOpen,
  children,
  onClose,
  closeOnOverlay = false,
  isBottomSheetOnMobile = false,
}: ModalProps) {
  const handleCloseOnOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (closeOnOverlay && e.target === e.currentTarget) onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="dialog-container" onClick={handleCloseOnOverlay}>
      <div className={`dialog-backdrop ${isOpen ? '' : 'backdrop-closed'}`} />
      <div
        className={`dialog-wrapper ${isBottomSheetOnMobile ? 'bottom-sheet-wrapper' : ''}`}
      >
        <div
          className={`dialog-content ${isBottomSheetOnMobile ? 'bottom-sheet-content' : ''}`}
        >
          <div
            className={`dialog-panel ${isBottomSheetOnMobile ? 'bottom-sheet-panel' : ''}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

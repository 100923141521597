// import {
//   Constants,
//   HTTP_METHODS,
//   VclApi,
//   VclApiGetType,
//   VclApiProps,
//   getHostDependentImageUrl,
//   useVclApi,
// } from 'vcl-common';
// import 'react-image-gallery/styles/css/image-gallery.css';
// import Hero from '../../sharedComponents/hero/hero';
// import Carousel from '../../sharedComponents/thumbnail/carousel';
// import { useCallback, useContext, useMemo, useState } from 'react';
// import FeatureWebcasts from '../../sharedComponents/landing/featureWebcasts';
// import { useFeed } from '../../hooks/useFeed';
// import { IPageMetadata } from '../../../../vcl.common/src/interfaces';

function LandingPage(props: any) {
  // const fetchFeed = useFeed();
  // const authContext = useContext(AuthContext);
  // const [pageMetadata, setPagemetadata] = useState<any>({});
  // const [rollupNoOfThumbnails, setRollupNoOfThumbnails] = useState<number>(4);

  // const apiGetProps = useMemo<VclApiProps>(() => {
  //   return {
  //     apiUrl: Constants.routes.api.pageMetadata,
  //     method: HTTP_METHODS.GET,
  //     getType: VclApiGetType.Item,
  //     useMsalAuthorization: true,
  //     loginRequest: loginRequest,
  //     fetchImmediately: true,
  //     params: { url: window.location.href },
  //   };
  // }, []);
  // const { apiResponse, apiRequestInProgress } = useVclApi<IPageMetadata>(apiGetProps);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const api = new VclApi();
  //       const params: any = { url: window.location.href };
  //       const response: any = await api.get(
  //         Constants.routes.api.pageMetadata,
  //         params,
  //         false,
  //       );
  //       const _pageMetadata: any = response.data;

  //       const feedSizeConf =
  //         _pageMetadata?.globalSettings?.organization?.configuration.filter(
  //           (config: any) => config.key === 'RollupNoOfThumbnails',
  //         );
  //       setPagemetadata(_pageMetadata);
  //       setRollupNoOfThumbnails(
  //         feedSizeConf && feedSizeConf.length > 0
  //           ? Number(feedSizeConf[0].value)
  //           : 4,
  //       );
  //     } catch (error) {
  //       console.error('Error fetching global settings', error);
  //     }
  //   })();
  // }, []);

  // const fetchLatest = useCallback(
  //   async (page: number) => {
  //     if (pageMetadata?.globalSettings?.siteSettings) {
  //       return await fetchFeed(
  //         'latest',
  //         Number(pageMetadata.globalSettings.organization?.id),
  //         'past',
  //         0,
  //         0,
  //         page,
  //         rollupNoOfThumbnails,
  //       );
  //     }
  //     return { items: [], totalItems: 0 };
  //   },
  //   [pageMetadata, fetchFeed, rollupNoOfThumbnails],
  // );

  // const fetchUpcoming = useCallback(
  //   async (page: number) => {
  //     if (pageMetadata?.globalSettings?.siteSettings) {
  //       return await fetchFeed(
  //         'latest',
  //         Number(pageMetadata.globalSettings.organization?.id),
  //         'future',
  //         0,
  //         0,
  //         page,
  //         rollupNoOfThumbnails,
  //       );
  //     }
  //     return { items: [], totalItems: 0 };
  //   },
  //   [pageMetadata, fetchFeed, rollupNoOfThumbnails],
  // );

  // const fetchLive = useCallback(
  //   async (page: number) => {
  //     if (pageMetadata?.globalSettings?.siteSettings) {
  //       return await fetchFeed(
  //         'latest',
  //         Number(pageMetadata.globalSettings.organization?.id),
  //         'current',
  //         0,
  //         0,
  //         page,
  //         rollupNoOfThumbnails,
  //       );
  //     }
  //     return { items: [], totalItems: 0 };
  //   },
  //   [pageMetadata, fetchFeed, rollupNoOfThumbnails],
  // );

  // const fetchFeatureWebcasts = useCallback(async () => {
  //   if (pageMetadata?.globalSettings?.organization?.id) {
  //     const api = new VclApi();
  //     const response = await api.get(
  //       Constants.routes.api.orgFeatureWebcastsFeed,
  //       {
  //         organizationId: pageMetadata.globalSettings.organization?.id,
  //         showExternalOnly: !authContext.authService?.isSignedIn(),
  //       },
  //       false,
  //     );
  //     return { items: response.data, totalItems: response.data?.length ?? 0 };
  //   } else {
  //     return { items: [], totalItems: 0 };
  //   }
  // }, [authContext, pageMetadata]);

  // const getHeroUrl = () => {
  //   if (
  //     pageMetadata &&
  //     pageMetadata.globalSettings &&
  //     pageMetadata.globalSettings.organization
  //   ) {
  //     if (pageMetadata.globalSettings.organization.landingPageImageUrl) {
  //       return pageMetadata.globalSettings.organization.landingPageImageUrl;
  //     } else if (pageMetadata.globalSettings.organization.heroUrl) {
  //       return pageMetadata.globalSettings.organization.heroUrl;
  //     }
  //   } else {
  //     return '';
  //   }
  // };

  return (
    <div>TBD</div>
    // <>
    //   <Hero
    //     heroImage={getHostDependentImageUrl(getHeroUrl())}
    //     imgtext={
    //       pageMetadata?.globalSettings?.organization?.landingPageTitle ??
    //       pageMetadata?.globalSettings?.organization?.title ??
    //       'Landing Page'
    //     }
    //   />
    //   <FeatureWebcasts
    //     headerTitle={'Featured Webcast'}
    //     fetchItems={fetchFeatureWebcasts}
    //   />
    //   <Carousel fetchItems={fetchLive} headerTitle="Live webcasts" />
    //   <Carousel fetchItems={fetchUpcoming} headerTitle="Upcoming webcasts" />
    //   <Carousel fetchItems={fetchLatest} headerTitle="Latest webcasts" />
    // </>
  );
}

export default LandingPage;

import React from 'react';
import './Banner.css';

interface BannerProps {
  title: string;
  date?: string;
  image?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Banner: React.FC<BannerProps> = ({
  title,
  date,
  image,
  className = '',
  style = {},
  children,
}) => {
  return (
    <section
      className={`banner ${className}`}
      style={style}
      aria-labelledby="banner-heading"
      role="banner"
    >
      {image && (
        <img
          src={image}
          alt={`Banner showcasing: ${title}`}
          className="banner-image"
        />
      )}
      <div className="banner-content">
        {date && (
          <p className="banner-date" aria-label={`Date: ${date}`}>
            {date}
          </p>
        )}
        <h1 id="banner-heading" className="banner-heading">
          {title}
        </h1>
        {children && <div className="banner-children">{children}</div>}
      </div>
    </section>
  );
};

export default Banner;

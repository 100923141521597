import React from 'react';
import './pagination.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const onPaginationClick = (direction: 'Prev' | 'Next') => {
    if (direction === 'Prev' && currentPage > 1) {
      onPageChange(currentPage - 1);
    } else if (direction === 'Next' && currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="paginationContainer">
      <button
        data-testid="prev-button"
        onClick={() => onPaginationClick('Prev')}
        disabled={currentPage <= 1}
        className="paginationButton"
      >
        <BsChevronLeft size={16} />
      </button>
      <button
        data-testid="next-button"
        onClick={() => onPaginationClick('Next')}
        disabled={currentPage >= totalPages}
        className="paginationButton"
      >
        <BsChevronRight size={16} />
      </button>
    </div>
  );
};

export default Pagination;

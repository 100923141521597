import './FeaturedWebcast.css';
import React from 'react';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

interface IFeaturedWebcastProps {
  startTime: string | undefined;
  relativeUrl: string;
  heroUrl: string;
  title: string;
  titleTwo?: string;
  description: string;
}

const FeaturedWebcast: React.FC<IFeaturedWebcastProps> = ({
  startTime,
  relativeUrl,
  heroUrl,
  title,
  titleTwo = '',
  description,
}) => {
  const dateObject = new Date(startTime as any);
  const localDate = dateObject.toLocaleDateString();
  const navigate = useNavigate();

  return (
    <div className="featured-webcast">
      <div className="featured-webcast-container">
        <div className="featured-webcast-header">
          <h2 className="featured-webcast-title">Featured Webcast</h2>
        </div>
        <div className="featured-webcast-image-wrapper">
          <a href={relativeUrl} className="featured-webcast-link">
            <img src={heroUrl} alt={title} className="featured-webcast-image" />
          </a>
        </div>
        <div className="featured-webcast-content">
          <a href={relativeUrl} className="featured-webcast-link">
            <div className="featured-webcast-date">{localDate}</div>
            <div className="featured-webcast-heading-container">
              <span className="featured-webcast-heading1">{title}.</span>
              <span className="featured-webcast-heading2"> {titleTwo}</span>
            </div>
            <div className="featured-webcast-description">
              <Markdown>{description}</Markdown>
            </div>
          </a>
        </div>
        <button type="button" className="primaryBtn featured-webcast-btn">
          Watch
        </button>
      </div>
    </div>
  );
};

export default FeaturedWebcast;

import React from 'react';
import { BiSolidSun } from 'react-icons/bi';
import { BsMoonStars } from 'react-icons/bs';
import './ThemeSwitch.css';
import { useTheme } from '../../hooks/useTheme';

const ThemeSwitch: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button className="toggle-theme" onClick={toggleTheme}>
      <div className="theme-icon">
        {theme === 'dark' ? (
          <BiSolidSun size={20} className="icon" />
        ) : (
          <BsMoonStars size={20} className="icon" />
        )}
      </div>
    </button>
  );
};

export default ThemeSwitch;

import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound from '../notFound/notFound';
import Webcast from '../webcast/webcast';
import Channel from '../channel/channel';
import Authenticator from '../authenticator/authenticator';

import {
  Constants,
  IPageMetadata,
  PageType,
  useLocalStorage,
  VclApiProps,
  HTTP_METHODS,
  VclApiGetType,
  useVclApi,
  AppType,
} from 'vcl-common';

const POLLING_INTERVAL = Number(process.env.REACT_APP_POLLING_INTERVAL);

function UrlResolver() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getApiKey } = useLocalStorage();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pollingTrigger, setPollingTrigger] = useState(0);

  // Try and get the API key from local storage. If an API key exists, it means the user has been authenticated to this page before.
  const apiKey = getApiKey();

  const apiGetProps = useMemo<VclApiProps>(() => {
    // The pageMetadata API takes the current URL and determines what type of page this is
    const params: Record<string, any> = { url: window.location.href };

    // If an API ID was found, add it to the API params
    if (apiKey) params.apiKey = apiKey;

    return {
      apiUrl: Constants.routes.api.pageMetadata,
      method: HTTP_METHODS.GET,
      appType: AppType.Web,
      getType: VclApiGetType.Item,
      useMsalAuthorization: false,
      fetchImmediately: true,
      params: params,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, pollingTrigger]);

  const { apiResponse, apiRequestInProgress } =
    useVclApi<IPageMetadata>(apiGetProps);

  useEffect(() => {
    if (apiResponse && apiResponse.data?.pageType === PageType.Webcast) {
      const intervalId = setInterval(() => {
        setPollingTrigger((prev) => prev + 1);
      }, POLLING_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [apiResponse]);

  let childComponent: JSX.Element | null = null;
  if (!apiRequestInProgress && !!apiResponse && !!apiResponse.data) {
    const pageMetadata = apiResponse.data;
    // The pageMetadata object shows which type of page this is. If the page requires authentication
    // and no metadata is returned, it means either the user isn't authenticated or provided the wrong
    // API ID. In that case, authenticate.
    if (!pageMetadata.urlInfo && pageMetadata.needsAuthentication) {
      childComponent = (
        <Authenticator
          pageMetadata={pageMetadata}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
        />
      );
    } else if (
      pageMetadata.urlInfo &&
      pageMetadata.pageType === PageType.Webcast
    ) {
      // If the current URL doesn't match the "correct" URL according to the metadata, navigate the user to the correct URL
      if (
        location.pathname.toLowerCase() !==
        pageMetadata.urlInfo.webcast.relativeUrl.toLowerCase()
      ) {
        navigate(pageMetadata.urlInfo.webcast.relativeUrl.toLowerCase());
      } else {
        childComponent = <Webcast pageMetadata={pageMetadata} />;
      }
    } else if (
      pageMetadata.urlInfo &&
      pageMetadata.pageType === PageType.Channel
    ) {
      if (
        location.pathname.toLowerCase() !==
        pageMetadata.urlInfo.channel.relativeUrl.toLowerCase()
      ) {
        navigate(pageMetadata.urlInfo.channel.relativeUrl.toLowerCase());
      } else {
        childComponent = <Channel pageMetadata={pageMetadata} />;
      }
    } else {
      childComponent = <NotFound />;
    }
  }
  return <>{!!childComponent && childComponent}</>;
}

export default UrlResolver;

import './VideoCard.css';
import { getHostDependentImageUrl, IWebcast } from 'vcl-common';

const convertDate = (date: string) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString();
};

interface VideoCardProps {
  item: IWebcast;
  isLive?: boolean;
  style?: any;
}

const VideoCard: React.FC<VideoCardProps> = ({
  item,
  style,
  isLive = false,
}) => {
  return (
    <div style={style} className="video-card">
      <a
        href={item.relativeUrl}
        aria-label={`Watch ${item?.title}`}
        className={`video-thumbnail ${isLive ? 'live-thumbnail' : ''}`}
      >
        <img
          src={getHostDependentImageUrl(item.thumbnailUrl)}
          alt={item?.title ?? 'Video Thumbnail'}
        />

        {isLive && <div className="live-indicator">LIVE</div>}
      </a>
      <div className="video-details">
        <a href={item.relativeUrl}>
          <h1 className="video-title">{item.title}</h1>
          <h3 className="channel-name">
            {item?.channel?.title ?? 'Volvo Cars'}
          </h3>
          {item.startTime && (
            <p className="broadcast-date">{convertDate(item.startTime)}</p>
          )}
        </a>
      </div>
    </div>
  );
};

export default VideoCard;

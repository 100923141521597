import Dropdown from '../../sharedComponents/FormControls/Dropdown/Dropdown';
import SearchBox from '../../sharedComponents/FormControls/SearchBox/SearchBox';
import { MdSort } from 'react-icons/md';
import { BiFilterAlt } from 'react-icons/bi';
import './VideoTabBar.css';
import { useQueryParams } from '../../hooks/useQueryParams';

interface IVideoTabBarProps {
  openFilter: () => void;
  handleSortChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSearch: (query: string) => void;
  sortBy: any;
}

const VideoTabBar: React.FC<IVideoTabBarProps> = ({
  openFilter,
  handleSortChange,
  handleSearch,
  sortBy,
}) => {
  const { filterCount } = useQueryParams();
  return (
    <div className="video-tab-container">
      <div className="top-row">
        <button onClick={openFilter} className="filter-btn filter-desktop">
          <BiFilterAlt size={20} style={{ marginRight: 8 }} />
          <span>Filters</span>
          {filterCount > 0 && <span className="filter-count">1</span>}
        </button>
        <div className="search-container">
          <SearchBox onSearch={handleSearch} placeholder="Search for videos" />
        </div>
      </div>
      <div className="bottom-row">
        <button onClick={openFilter} className="filter-btn filter-mobile">
          <BiFilterAlt size={20} style={{ marginRight: 8 }} />
          <span>Filters</span>
        </button>
        <div className="sort-container">
          <Dropdown
            id="sort"
            name="sort"
            label="Sort by"
            options={sortOptions}
            onChange={handleSortChange}
            placeholder="Select"
            icon={MdSort}
            defaultValue={sortBy}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoTabBar;

const sortOptions = [
  {
    label: 'Oldest',
    value: false,
  },
  {
    label: 'Latest',
    value: true,
  },
];

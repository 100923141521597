import { Link } from 'react-router-dom';
import SideNavbar from '../sideNavbar/sideNavbar';
import ThemeSwitch from '../Switch/ThemeSwitch';
import './header.css';
import IMAGES from '../../constants/images';
import { useTheme } from '../../hooks/useTheme';

function Header() {
  const { theme } = useTheme();
  const logo =
    theme === 'light' ? IMAGES.LIGHT_THEME_LOGO : IMAGES.DARK_THEME_LOGO;

  return (
    <header className="header">
      <div className="headerContainer">
        <Link to="/" className="logo">
          <img src={logo} alt="Volvo Logo" />
        </Link>
        <div className="headerOptions">
          <ThemeSwitch />
          <SideNavbar />
        </div>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';
import './VideoCardSkeleton.css';

const VideoCardSkeleton: React.FC = () => {
  return (
    <div className="video-card skeleton">
      <div className="video-thumbnail skeleton-box"></div>
      <div className="video-details">
        <div className="video-title skeleton-box" />
        <div className="channel-name skeleton-box" />
        <div className="broadcast-date skeleton-box" />
      </div>
    </div>
  );
};

export default VideoCardSkeleton;

import React, { ComponentProps } from 'react';
import { IconType } from 'react-icons';
import './Dropdown.css';

type OptionType = {
  value: any;
  label: string;
};

interface IDropdownProps extends ComponentProps<'select'> {
  options: OptionType[];
  label?: string;
  icon?: IconType;
  required?: boolean;
  placeholder?: string;
}

const Dropdown: React.FC<IDropdownProps> = ({
  options,
  id,
  name,
  label,
  placeholder,
  icon: Icon,
  required = false,
  className = '',
  ...selectProps
}) => {
  return (
    <div className={`dropdown-container ${className}`}>
      {label && (
        <label htmlFor={id} className="dropdown-label">
          {label}
          {required && <span className="dropdown-label-required">*</span>}
        </label>
      )}

      <div className="dropdown-select-wrapper">
        {Icon && (
          <div className="dropdown-icon-wrapper">
            <Icon aria-label="dropdown-icon" />
          </div>
        )}

        <select
          id={id}
          name={name}
          required={required}
          className={`dropdown-select ${Icon ? 'dropdown-select-with-icon' : ''}`}
          {...selectProps}
        >
          {placeholder && <option value="">{placeholder}</option>}

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="dropdown-chevron-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

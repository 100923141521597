import DatePicker from '../../sharedComponents/FormControls/Datepicker/Datepicker';
import { useQueryParams } from '../../hooks/useQueryParams';

function DateRange() {
  const { filterData } = useQueryParams();

  return (
    <div>
      <DatePicker
        defaultValue={filterData.startTime || ''}
        label="Start Date"
        id="startTime"
        name="startTime"
      />
      <br />
      <DatePicker
        defaultValue={filterData.endTime || ''}
        label="End Date"
        id="endTime"
        name="endTime"
      />
    </div>
  );
}

export default DateRange;

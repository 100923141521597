import './webcast.css';
import {
  Constants,
  IPageMetadata,
  VIEWING_STATE,
  useLocalStorage,
  useMsalUtils,
  loginRequestWeb,
  IWebcast,
} from 'vcl-common';
import { useState } from 'react';
import Carousel from '../../sharedComponents/thumbnail/carousel';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { isEventInFuture } from '../../utils/utils';
import CountDownContainer from './Countdown/CountDownContainer';
import VideoContainer from './Video/VideoContainer';
import VideoCard from '../../sharedComponents/VideoCard/VideoCard';

export function formatDateAndTime(dateTimeString: string, includeTime = true) {
  const date = new Date(dateTimeString);
  const formattedDate = format(date, 'MM/dd/yyyy');
  const formattedTime = format(date, 'hh:mm a');
  return includeTime ? `${formattedDate} at ${formattedTime}` : formattedDate;
}

interface IWebcastProps {
  pageMetadata: IPageMetadata;
}

function Webcast(props: IWebcastProps) {
  const { getIsAdminOfCurrentPage } = useLocalStorage();
  const { isSignedIn } = useMsalUtils({ loginRequest: loginRequestWeb });

  const isAdmin = getIsAdminOfCurrentPage();
  const pageMetadata = props.pageMetadata;
  const globalSettings = pageMetadata?.globalSettings;
  const webcast = pageMetadata?.urlInfo?.webcast;

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useLocation();
  const viewType = new URLSearchParams(navigate.search).get('viewType');

  const feedParams = {
    webcastId: Number(webcast?.id),
    organizationId: Number(globalSettings?.organization?.id),
  };

  // const webcastEndGracePeriodMinutes = globalSettings?.siteSettings
  //   ?.WebcastEndGracePeriodMinutes
  //   ? globalSettings.siteSettings.WebcastEndGracePeriodMinutes * 60 * 1000
  //   : 5000;

  const [isFutureWebcast, setIsFutureWebcast] = useState(
    webcast ? isEventInFuture(webcast) : false,
  );
  const isCountDown =
    (viewType === null && isFutureWebcast) || viewType === 'countDown';

  const videoIsVisible = () => {
    return (!isFutureWebcast && !isCountDown) || viewType === 'webcast';
  };

  const countdownIsVisible = () => {
    return (isFutureWebcast && viewType !== 'webcast') || isCountDown;
  };

  const endedInfoIsVisible = () => {
    return (
      !(isSignedIn() && isAdmin) &&
      webcast &&
      webcast.viewingState === VIEWING_STATE.ended &&
      webcast.postEventBehavior === 0
    );
  };

  const showWebcastEndedMessage = () => {
    return (
      <section id="endedSec">
        <div className="vl-ended">
          <div className="vl-Alert--info vl-u-center-inline">
            {Constants.webcast?.webcastEndedMessage}
            <br />
            {Constants.webcast?.webcastEndedContactInfoPrefix}{' '}
            <a href={`mailto:${webcast?.contactEmail}`}>
              {webcast?.contactEmail}
            </a>
          </div>
        </div>
      </section>
    );
  };

  // const shouldRedirectWebcast = () => {
  //   return (
  //     webcast &&
  //     webcast.viewingState === VIEWING_STATE.endedredirect &&
  //     webcast.postEventBehavior === 2
  //   );
  // };

  // const redirectWebcast = () => {
  //   const timer = setTimeout(() => {
  //     window.location.href = webcast ? webcast?.redirectUrl : '';
  //   }, webcastEndGracePeriodMinutes);

  //   return () => clearTimeout(timer);
  // };

  const canShowCTA = () => {
    return (
      videoIsVisible() &&
      webcast?.enableCTAButton &&
      webcast?.showCTAButtonInVod
      // webcast?.vodFirstPublished === null
    );
  };

  return (
    <>
      {isLoading || !webcast ? (
        <></>
      ) : (
        <>
          {!endedInfoIsVisible() && (
            <div className="">
              {countdownIsVisible() ? (
                <CountDownContainer
                  pageMetadata={pageMetadata}
                  setIsFutureWebcast={(value) => setIsFutureWebcast(value)}
                  webcast={webcast}
                  isShowEndInfo={endedInfoIsVisible}
                  canShowCTA={canShowCTA}
                />
              ) : (
                <VideoContainer
                  isShowEndInfo={endedInfoIsVisible}
                  pageMetadata={pageMetadata}
                  webcast={webcast}
                  canShowCTA={canShowCTA}
                  videoIsVisible={videoIsVisible}
                  isAdmin={isAdmin}
                />
              )}
            </div>
          )}
          {/* we need to show the recommended webcasts only if the event is live or past.*/}
          {videoIsVisible() && !endedInfoIsVisible() && (
            <>
              {webcast?.channel && (
                <>
                  <br />
                  <div className="carouselContainer carouselContainerBg">
                    <Carousel
                      headerTitle={`More from ${webcast?.channel?.title ?? 'Volvo Cars'}`}
                      feedType="webcastFeed"
                      status="past"
                      {...feedParams}
                      renderItem={(item: IWebcast, index: number) => (
                        <VideoCard key={index} item={item} />
                      )}
                    />
                  </div>
                </>
              )}
              <div className="carouselContainer">
                <Carousel
                  headerTitle="Recommended"
                  feedType="recommendedFeed"
                  status="past"
                  {...feedParams}
                  renderItem={(item: IWebcast, index: number) => (
                    <VideoCard key={index} item={item} />
                  )}
                />
              </div>
            </>
          )}

          {/* Ended webcast message */}
          {endedInfoIsVisible() && showWebcastEndedMessage()}

          {/* Redirect after a webcast ends */}
          {/* {shouldRedirectWebcast() && redirectWebcast()} */}
        </>
      )}
    </>
  );
}

export default Webcast;

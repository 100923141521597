import React, { useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';

import './VideoFilter.css';
import Modal from '../../sharedComponents/Modal/Modal';

interface FilterData {
  [key: string]: () => React.ReactElement;
}

interface VideoFilterProps {
  filterData: FilterData;
  onClose: () => void;
  isOpen: boolean;
}

const VideoFilter: React.FC<VideoFilterProps> = ({
  filterData,
  onClose,
  isOpen,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(
    Object.keys(filterData)[0],
  );
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const filters: Record<string, any> = {};
    formData.forEach((value, key) => {
      filters[key] = value;
    });

    queryParams.set('filter', JSON.stringify(filters));

    navigate(`${pathname}?${queryParams.toString()}`);
    onClose();
  };

  const onClearFilters = () => {
    queryParams.delete('filter');
    navigate(`${pathname}?${queryParams.toString()}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="filter-panel-container">
          <div className="filter-panel-header">
            <h1>Filter</h1>
            <button
              type="button"
              onClick={onClose}
              aria-label="Close Filter Panel"
              className="close-button"
            >
              <HiXMark strokeWidth={1} size={20} />
            </button>
          </div>
          <div className="filter-panel-content">
            <div className="filter-category-container">
              {Object.keys(filterData).map((key) => (
                <div key={key} className="filter-category-item">
                  {selectedCategory === key && (
                    <div className="active-indicator" />
                  )}
                  <button
                    style={{ border: 'none', background: 'none' }}
                    type="button"
                    onClick={() => setSelectedCategory(key)}
                  >
                    {key}
                  </button>
                </div>
              ))}
            </div>
            <div className="filter-divider" />
            <div className="filter-data-container">
              {Object.entries(filterData).map(([key, Component]) => (
                <div
                  key={key}
                  className={selectedCategory !== key ? 'hidden' : ''}
                >
                  <Component />
                </div>
              ))}
            </div>
          </div>
          <div className="filter-panel-footer">
            <div className="footer-shadow" />
            <div className="footer-content">
              <button
                type="reset"
                onClick={onClearFilters}
                className="clear-filters-button"
              >
                Clear Filters
              </button>
              <button
                style={{ height: 38 }}
                type="submit"
                className="primaryBtn"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default VideoFilter;

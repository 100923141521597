import { useState } from 'react';
import {
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaTwitter,
} from 'react-icons/fa';
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import './sideNavbar.css';
import { useLocalStorage, useMsalUtils, loginRequestWeb } from 'vcl-common';
import ProfileCard from '../profile/profileCard';

function SideNavbar() {
  const [adminMenuVisible, setAdminMenuVisible] = useState(false);
  const { getOrgUserProfile, getUserProfilePhotoUrl, getIsAdminOfCurrentPage } =
    useLocalStorage();
  const { isSignedIn, signIn, signOut } = useMsalUtils({
    loginRequest: loginRequestWeb,
  });

  const toggleAdminMenu = () => setAdminMenuVisible((prev) => !prev);

  const isAdmin = getIsAdminOfCurrentPage();
  const userProfile = getOrgUserProfile();
  const profilePhotoUrl = getUserProfilePhotoUrl();

  return (
    <>
      <div className="sideNavbarContainer">
        <button
          className="btn menuBtn"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          Menu
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header sideHeaderContainer">
            <img
              src="https://www.volvocars.com/static/shared/images/volvo-wordmark-black.svg"
              className="d-inline-block align-top"
              alt="logo"
            />
            {adminMenuVisible && (
              <VscChevronLeft
                className="sideNavbarLeftBtn"
                onClick={toggleAdminMenu}
              />
            )}
            <h6>{adminMenuVisible ? 'Admin' : ''}</h6>
            <button
              className="btn-close text-reset sideNavbarCloseBtn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            {!adminMenuVisible && (
              <div className="sideNavbarMenu">
                {isSignedIn() ? (
                  <>
                    {userProfile?.displayName && (
                      <ProfileCard
                        name={userProfile?.displayName}
                        imageUrl={profilePhotoUrl}
                      />
                    )}
                    <div className="navbarMenuTab" onClick={() => signOut()}>
                      Sign Out
                    </div>
                  </>
                ) : (
                  <div className="navbarMenuTab" onClick={() => signIn()}>
                    Sign In
                  </div>
                )}
                {isAdmin && (
                  <div
                    className="navbarMenuTab sideNavbarrightIcon"
                    onClick={toggleAdminMenu}
                  >
                    Admin <VscChevronRight />
                  </div>
                )}
                <div className="navbarMenuTab">Support</div>
                <div className="sideNavbarSocialIcons">
                  <FaFacebookSquare />
                  <FaInstagram />
                  <FaTwitter />
                  <FaYoutube size="30px" />
                </div>
              </div>
            )}
            {adminMenuVisible && (
              <div className="sideNavbarMenu">
                <div
                  className="navbarMenuTab"
                  onClick={() =>
                    window.open(
                      `${process.env['REACT_APP_ADMINWEB_URL'] as string}`,
                      '_blank',
                      '_blank',
                    )
                  }
                >
                  Admin Center
                </div>
                <div className="navbarMenuTab">How-to guides</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNavbar;

import './CallToAction.css';

type Webcast = {
  ctaButtonHeadline?: string;
  ctaDescriptionAsHTML?: string;
  ctaButtonTitle?: string;
  ctaButtonUrl?: string;
};

interface CallToActionProps {
  webcast: Webcast;
}

const CallToAction: React.FC<CallToActionProps> = ({ webcast }) => {
  return (
    <>
      <div className="cta-container">
        <div className="cta-text">
          <p>{webcast?.ctaButtonHeadline}</p>
          <div className="ctaDescrip">{webcast?.ctaDescriptionAsHTML}</div>
        </div>
        <button
          className="cta-button"
          aria-label={webcast?.ctaButtonTitle || 'Call to action button'}
          onClick={() => window.open(webcast?.ctaButtonUrl, '_blank')}
        >
          {webcast?.ctaButtonTitle}
        </button>
      </div>

      <div className="horizontal-line"></div>
    </>
  );
};

export default CallToAction;

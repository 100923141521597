import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IWebcastFilter {
  includePast?: boolean;
  includeCurrent?: boolean;
  includeFuture?: boolean;
  isDeleted?: boolean;
  searchText?: string;
  orderBy?: string;
  orderByDescending?: boolean;
  startTime?: string;
  endTime?: string;
  channelId?: number;
  includeInternal?: boolean;
  organizationId?: number;
  webcastId?: number;
}

export const useQueryParams = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const sortBy = queryParams.get('sortBy') || '';
  const filterString = queryParams.get('filter') || '{}';
  const searchText = queryParams.get('searchText') || '';
  const channelId = queryParams.get('channelId') || '';

  const filterData: IWebcastFilter = useMemo(() => {
    try {
      const parsedFilter: IWebcastFilter = JSON.parse(filterString);
      if (channelId) {
        parsedFilter.channelId = Number(channelId);
      }
      return parsedFilter;
    } catch (error) {
      console.error('Invalid filter JSON:', error);
      return {};
    }
  }, [filterString, channelId]);

  const filterCount = Object.values(JSON.parse(filterString)).filter(
    Boolean,
  ).length;

  const updateQueryParams = useCallback(
    (key: string, value: string) => {
      const newParams = new URLSearchParams(queryParams.toString());

      if (value === '') {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }

      navigate(`${pathname}?${newParams.toString()}`, { replace: true });
    },
    [queryParams, navigate, pathname],
  );

  return {
    navigate,
    pathname,
    sortBy,
    filterData,
    filterCount,
    searchText,
    updateQueryParams,
  };
};

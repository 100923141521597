import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

type Theme = 'light' | 'dark';

interface IThemeContext {
  theme: Theme;
  toggleTheme: () => void;
}

const VCL_WEB_THEME = 'vcl-web-theme';
const VCL_WEB_THEME_MANUAL = 'vcl-web-theme-manual';

const ThemeContext = createContext<IThemeContext | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const getSystemTheme = useCallback(
    (): Theme =>
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light',
    [],
  );

  const storedTheme = localStorage.getItem(VCL_WEB_THEME) as Theme | null;
  const storedManualFlag = localStorage.getItem(VCL_WEB_THEME_MANUAL);
  const isManuallySet = storedManualFlag === 'true';

  const [theme, setTheme] = useState<Theme>(storedTheme || getSystemTheme);
  const [manuallySet, setManuallySet] = useState(isManuallySet);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem(VCL_WEB_THEME, theme);
  }, [theme]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (!manuallySet) {
        setTheme(e.matches ? 'dark' : 'light');
      } else {
        const systemTheme = e.matches ? 'dark' : 'light';
        if (systemTheme !== theme) {
          setManuallySet(false);
          localStorage.removeItem(VCL_WEB_THEME_MANUAL);
          setTheme(systemTheme);
        }
      }
    };

    mediaQuery.addEventListener('change', handleSystemThemeChange);
    return () =>
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
  }, [theme, manuallySet]);

  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setManuallySet(true);
    localStorage.setItem(VCL_WEB_THEME, newTheme);
    localStorage.setItem(VCL_WEB_THEME_MANUAL, 'true');
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

import Markdown from 'react-markdown';
import './hero.css';

interface IHeroProps {
  heroImage: string;
  descText?: any;
  preHeader?: string;
  imgtext: string;
}

function Hero({ heroImage, descText, imgtext, preHeader = '' }: IHeroProps) {
  const hasDescText =
    descText && (descText.descriptionFieldContainsHTML || descText.description);
  return (
    <>
      <div className="hero-container">
        <img src={heroImage} alt="heroImage" />
        <div className="hero-content">
          <div className="preheader">{preHeader}</div>
          <div className="channel-name">{imgtext}</div>
          <div className="">
            {hasDescText &&
              (descText.descriptionFieldContainsHTML ? (
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: descText.descriptionAsHTML,
                  }}
                />
              ) : (
                <div className="description">
                  <Markdown>{descText.description}</Markdown>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;

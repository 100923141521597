import React, { useEffect, useMemo, useState } from 'react';
import VideoCard from '../../sharedComponents/VideoCard/VideoCard';
import './AllVideos.css';

import VideoFilter from '../VideoFilter/VideoFilter';
import DateRange from '../VideoFilter/DateRange';
import {
  AppType,
  Constants,
  HTTP_METHODS,
  useLocalStorage,
  useVclApi,
  VclApiGetType,
  VclApiProps,
} from 'vcl-common';
import VideoTabBar from './VideoTabBar';
import VideoCardSkeleton from '../../sharedComponents/VideoCard/VideoCardSkeleton';
import { useQueryParams } from '../../hooks/useQueryParams';

const VideoCardSkeletonList = ({ count }: { count: number }) => (
  <>
    {Array.from({ length: count }).map((_, index) => (
      <VideoCardSkeleton key={index} />
    ))}
  </>
);

const AllVideos: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { getApiKey } = useLocalStorage();
  const { sortBy, filterData, searchText, updateQueryParams } =
    useQueryParams();

  const [videosState, setVideosState] = useState({
    videos: [] as any[],
    page: 1,
    hasMore: true,
    isLoading: false,
  });

  const apiGetProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.allVideos,
      method: HTTP_METHODS.GET,
      appType: AppType.Web,
      getType: VclApiGetType.Batch,
      fetchImmediately: true,
      params: {
        filter: JSON.stringify({
          ...filterData,
          orderByDescending: sortBy === 'true',
          searchText,
        }),
        page: videosState.page,
        pageSize: 8,
        apiKey: getApiKey(),
      },
    };
  }, [getApiKey, filterData, sortBy, searchText, videosState.page]);

  const { apiRequestInProgress, apiResponseBatch } =
    useVclApi<any>(apiGetProps);

  useEffect(() => {
    setVideosState({
      videos: [],
      page: 1,
      hasMore: true,
      isLoading: true,
    });
  }, [filterData, sortBy, searchText]);

  useEffect(() => {
    setVideosState((prev) => ({ ...prev, isLoading: apiRequestInProgress }));

    if (Array.isArray(apiResponseBatch?.items)) {
      setVideosState((prev) => {
        if (apiResponseBatch.items.length === 0) {
          return { ...prev, hasMore: false };
        }

        const newVideos = apiResponseBatch.items.filter(
          (newVideo) =>
            !prev.videos.some(
              (existingVideo) => existingVideo.id === newVideo.id,
            ),
        );

        return {
          ...prev,
          videos:
            prev.page === 1
              ? apiResponseBatch.items
              : [...prev.videos, ...newVideos],
          hasMore: newVideos.length > 0,
        };
      });
    }
  }, [apiResponseBatch, apiRequestInProgress]);

  const handleScroll = () => {
    if (!videosState.hasMore || videosState.isLoading) return;

    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const scrollThreshold = document.documentElement.offsetHeight - 300;

    if (scrollPosition >= scrollThreshold) {
      setVideosState((prev) => {
        if (!prev.hasMore || prev.isLoading) return prev;
        return { ...prev, page: prev.page + 1 };
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => setIsModalOpen(false);

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateQueryParams('sortBy', e.target.value);
  };

  const handleSearch = (query: string) => {
    updateQueryParams('searchText', query);
  };

  return (
    <div className="video-flex-container">
      <VideoTabBar
        openFilter={() => setIsModalOpen(true)}
        handleSortChange={handleSortChange}
        handleSearch={handleSearch}
        sortBy={sortBy}
      />
      <br />
      <br />
      <div className="video-flex">
        {videosState.videos.map((video: any, index) => (
          <VideoCard key={`${video.id}-${index}`} item={video} />
        ))}

        {videosState.isLoading && <VideoCardSkeletonList count={8} />}

        {!videosState.isLoading && videosState.videos.length === 0 && (
          <div className="no-videos-message">No videos found</div>
        )}
      </div>

      {isModalOpen && (
        <VideoFilter
          filterData={{
            'Date Range': () => <DateRange />,
          }}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default AllVideos;

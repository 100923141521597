import React from 'react';
import Markdown from 'react-markdown';
import { formatDateAndTime } from '../webcast';
import { statsMinimal } from '../../../sharedComponents/menu/statistics/statsMinimal';
import { IWebcast } from 'vcl-common';

interface VideoDetailProps {
  webcast: IWebcast;
  isAdmin: boolean;
}

const VideoDetail: React.FC<VideoDetailProps> = ({ webcast, isAdmin }) => {
  // we need to change this to network call in the future
  const stats = statsMinimal;
  const noOfOnlineViewers = stats.OnlineViewers ?? 0;

  const showStatistics = () => {
    return !webcast?.hideStatistics || isAdmin;
  };

  if (!webcast) {
    return null;
  }

  return (
    <div className="vl-videoInfo vl-always-center">
      {showStatistics() &&
        webcast?.viewingState <= 1 &&
        noOfOnlineViewers > 0 && (
          <div className="vl-views" id="viewCounter">
            {noOfOnlineViewers} watching
          </div>
        )}
      {showStatistics() && webcast?.viewingState > 1 && (
        <pre className="vl-views">{webcast?.viewCount ?? 0} views</pre>
      )}
      <div className="vl-broadcastDate">
        <span>
          {webcast?.startTime && formatDateAndTime(webcast?.startTime, false)}
        </span>
        {webcast?.channel?.title && (
          <span>
            <a
              href={`/${webcast?.channel.pathName}`}
              target="_blank"
              rel="noreferrer"
              className="vl-channel"
            >
              {webcast?.channel.title}
            </a>
          </span>
        )}
      </div>
      <div className="vl-title">
        <h2 className="vl-title1">{webcast?.title}.</h2>
        <h2 className="vl-title2">{webcast?.title2 ?? ''}</h2>
      </div>
      {webcast?.descriptionFieldContainsHTML ? (
        <div
          className="vl-description"
          dangerouslySetInnerHTML={{ __html: webcast?.descriptionAsHTML }}
        />
      ) : (
        <div className="vl-description">
          <Markdown>{webcast?.description}</Markdown>
        </div>
      )}
    </div>
  );
};

export default VideoDetail;
